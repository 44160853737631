/* global google */

import { Loader } from "@googlemaps/js-api-loader";

class GoogleMap {
  constructor(markerData, gmapsApiKey, zoom, mapWidgetId) {
    this.markers = [];
    this.gmap = null;
    this.mapBounds = null;
    this.currentInfoWindow = null;
    this.loader = new Loader({ apiKey: gmapsApiKey });
    this.googleMapsLoaded = this.loader.importLibrary('maps');

    this.googleMapsLoaded.then(() => {
      this.initMapAndComponents(mapWidgetId);
      this.createMarkers(markerData);
      this.render(zoom);
    });
  }

  initMapAndComponents(mapWidgetId) {
    const mapElement = mapWidgetId
      ? document.getElementById(`map_${mapWidgetId}`)
      : document.getElementById("map");

    this.gmap = new google.maps.Map(mapElement);
    this.mapBounds = new google.maps.LatLngBounds();
  }

  createMarkers(markerData) {
    if (markerData.length === 0) {
      const defaultPosition = new google.maps.LatLng(0, 0);
      this.gmap.setCenter(defaultPosition);
    }

    markerData.forEach((marker) => {
      const { title, lat, lng, infoWindowHTML } = marker;
      const position = new google.maps.LatLng(lat, lng);

      const newMarker = new google.maps.Marker({
        title,
        position,
      });

      this.addMarkerInfoWindow(infoWindowHTML, newMarker);
      this.markers.push(newMarker);

      if (!this.gmap.getCenter()) {
        this.gmap.setCenter(position);
      }

      this.addAltAttributeToAreas();
    });
  }

  addMarkerInfoWindow(content, marker) {
    const infoWindow = new google.maps.InfoWindow({ content });

    marker.addListener("click", () => {
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close();
      }

      infoWindow.open(this.gmap, marker);
      this.currentInfoWindow = infoWindow;
    });
  }

  setMapForMarkers() {
    this.markers.forEach((marker) => marker.setMap(this.gmap));
  }

  fitBoundsToMarkers() {
    this.markers.forEach((marker) => this.mapBounds.extend(marker.getPosition()));
    this.gmap.fitBounds(this.mapBounds);
  }

  setZoomLevel(zoom) {
    this.gmap.setZoom(zoom);
  }

  render(zoom) {
    this.setMapForMarkers();

    if (zoom === "auto") {
      this.fitBoundsToMarkers();
    } else {
      this.setZoomLevel(Number(zoom));
    }
  }

  addAltAttributeToAreas() {
    google.maps.event.addListener(this.gmap, 'tilesloaded', function() {
      const areas = document.querySelectorAll('.map_container map area');
      areas.forEach(function(area, index) {
        area.alt = `Google Maps Clickable Area ${index + 1}`;
      });
    });
  }
}

// Expose to the browser via window
if (typeof window !== "undefined") {
  window.GoogleMap = GoogleMap;
}

export default GoogleMap;
